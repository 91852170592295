import * as React from "react"

import Layout from "../components/layout"
import Header from "../components/section/header"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Header title="404: Not Found" />
    <>
      <section className="flex flex-col items-center gap-4 py-48">
        <p className="text-xs">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </section>
    </>
  </Layout>
)

export default NotFoundPage
